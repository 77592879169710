import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper';
import SectionMedia from '../SectionMedia';
import ModalBoxInline from '../../../../components/ModalBoxInline';
import styles from './styles.module.css';

class ProductsLayout extends React.Component {

  constructor(props) {

    super(props);

    const align = props.section.styles.align ? props.section.styles.align : 'Left';
    const full = props.section.styles.full === true ? 'Full' : '';

    let columns;
    if (props.section && props.section.styles
      && props.section.styles.columns) ({ columns } = props.section.styles);

    this.state = {
      full,
      align,
      showModal: false,
      modalIndex: 0,
      columns,
    };

    this.createImage = this.createImage.bind(this);
    this.createContent = this.createContent.bind(this);
    this.createModal = this.createModal.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

  }

  handleCardClick(index) {

    this.setState({
      showModal: true,
      modalIndex: index,
    });

  }

  handleCloseModal() {

    this.setState({
      showModal: false,
    });

  }

  createImage(item, cIndex, eIndex, wrapperStyle, elementStyle, align) {

    return (<SectionMedia
      id={item.content.id}
      mediaType={item.content.icon ? 'ICON' : 'IMAGE'}
      wrapperStyle={wrapperStyle}
      elementStyle={elementStyle}
      align={align}
      src={item.content.CDNLink ? item.content.CDNLink : `${item.content.src}`}
      alt={item.content.alt}
      data={item.content}
      images={this.props.images}
      pagePathList={this.props.pagePathList}
      articlePathList={this.props.articlePathList}
      filePathList={this.props.filePathList}
    />);

  }

  createContent(item, index, cols) {

    let style;
    if (item.styles.backgroundColor.active) {

      let { backgroundColor } = item.styles;
      if (
        item.styles.backgroundColor.solid === ''
        && item.styles.backgroundColor.gradient.from === ''
      ) backgroundColor = { solid: '#ffffff' };

      style = formColor(
        backgroundColor,
        false,
        item.styles.backgroundColor.opacity,
      );

    }

    const align = `align${item.align}`;
    const design = this.props.layout === 'left' ? 'Left' : this.props.layout === 'design2' ? 'Design2' : '';

    const texts = [];
    let image;
    item.data.forEach((elem, i) => {

      if (elem.active && elem.type === 'IMAGES/IMAGE') {

        image = (
          <div className={`${styles[`productsImageWrapper${design}`]}${this.props.layout === 'left' ? ` ${styles.productsCard}` : ''}`}>
            { this.createImage(elem, index, i, '', `productsImageElement${this.props.layout === 'design2' ? 'Design2' : ''}`, item.align)}
          </div>
        );

      } else if (elem.active && (elem.type === 'HEADINGS/HEADING-THREE' || elem.type === 'PARAGRAPH/PARAGRAPH')) {

        if (this.props.layout === 'design2') {

          let styleName;
          let data;

          if (elem.type === 'HEADINGS/HEADING-THREE') {

            styleName = `${styles.productsComponentHeading} ${styles.exceptionWeight} ${styles[align]}`;
            data = `<h3>${elem.text}</h3>`;


          } else {

            styleName = `${styles.productsComponentParagraph} ${styles[align]}`;
            data = `<span>${elem.text}</span>`;

          }

          const text = (
            <div
              key={`${this.props.section._id}_elem_${index}${i}`}
              className={styleName}
            >
              { HTMLParser(data) }
            </div>
          );

          texts.push(text);

        } else if (this.props.layout === 'left') {

          if (i === 1 || i === 2) {

            let data;
            let styleName;

            if (elem.type === 'HEADINGS/HEADING-THREE') {

              styleName = `${styles.productsComponentHeading} ${styles.exceptionWeight} ${styles[align]}`;
              data = `<h3>${elem.text}</h3>`;

            } else {

              styleName = `${styles.productsComponentParagraph} ${styles[align]}`;
              data = `<span>${elem.text}</span>`;

            }

            const text = (
              <div
                key={`${this.props.section._id}_elem_${index}${i}_section`}
                className={styleName}
              >
                {HTMLParser(data)}
              </div>
            );

            texts.push(text);

          }

        }

      }

    });

    if (this.props.layout === 'design2') {

      return (
        <div
          key={`${this.props.section._id}_layout_${index}`}
          className={`${cols} ${styles.colEqualHeights}`}
        >
          <div
            data-layout="Products"
            className={`${styles[`productsComponentsWrapper${design}`]}`}
            style={style}
          >
            { image }
            <div className={styles.productsTextBlockDesign2Wrapper}>
              <div className={styles.productsTextBlockDesign2}>
                { texts }
              </div>
            </div>
          </div>
        </div>
      );

    } else if (this.props.layout === 'left') {

      return (
        <div
          key={`${this.props.section._id}_layout_${index}`}
          className={`${cols} ${styles.colEqualHeights}`}
        >
          {this.createModal(index, item)}
          <div
            data-layout="Products"
            className={`${styles.productsComponentsWrapperLeft} ${styles.productsCardWrapper}`}
            style={style}
            onClick={() => this.handleCardClick(index)}
          >
            { image }
            <div className={styles.productsTextWrapper}>
              { texts }
            </div>
          </div>
        </div>
      );

    }

    return null;

  }

  createModal(index, item) {

    const align = `align${item.align}`;

    let image;
    const texts = [];
    let first = false;
    item.data.forEach((elem, i) => {

      if (elem.active && elem.type === 'IMAGES/IMAGE') {

        image = (
          <div className={styles.modalLeftColumn}>
            { this.createImage(elem, index, i, 'productsImageModalWrapper', 'productsImageModal', item.align)}
          </div>
        );

      } else if (elem.active && (elem.type === 'HEADINGS/HEADING-THREE' || elem.type === 'PARAGRAPH/PARAGRAPH')) {

        let styleName;
        let data;

        if (elem.type === 'HEADINGS/HEADING-THREE') {

          styleName = `${styles.productsComponentModalHeading} ${styles.exceptionWeight} ${styles[align]}`;
          data = `<h3>${elem.text}</h3>`;
          first = true;

        } else {

          styleName = `${styles.productsComponentModalParagraph} ${styles[align]}`;
          data = `<span>${elem.text}</span>`;

        }

        const text = (
          <div
            key={`${this.props.section._id}_modal_elem_${index}${i}`}
            className={styleName}
          >
            {HTMLParser(data)}
          </div>
        );

        if (i === 3) texts.splice(texts.length === 1 && !first ? 0 : 1, 0, text);
        else texts.push(text);

      }

    });

    const data = (
      <div className={styles.productsModalWrapper}>
        { image }
        <div className={styles.modalRightColumn} style={!image ? { width: '100%' } : undefined}>
          <div className={styles.modalCloseButton}>
            <i
              className={`entypo icon-cancel ${styles.modalCloseIcon}`}
              onClick={this.handleCloseModal}
            />
          </div>
          <div className={styles.modalTextWrapper}>
            { texts }
          </div>
        </div>
      </div>
    );

    if (this.state.modalIndex === index) {

      return (
        <ModalBoxInline
          isOpen={this.state.showModal}
          id="productsModal"
          onCloseClick={this.handleCloseModal}
          data={data}
          noBox
          noHeaderOnMobile
        />
      );

    }

    return null;

  }

  render() {

    let cols;
    if (this.state.columns === 1) cols = 'col-12';
    else cols = `col-12 col-sm-6 col-md-${this.state.columns ? (12 / this.state.columns) : 4}`;

    const activeComponents = [];
    const top = [];
    this.props.section.data.map((item, index) => {

      if (item.active && item.type === 'COMPONENT') {

        const content = this.createContent(item, index, cols);
        activeComponents.push(content);

      } else if (
        item.active
        && (item.type === 'HEADINGS/HEADING-TWO' || item.type === 'PARAGRAPH/PARAGRAPH')
      ) {

        let styleName;
        let data;
        if (item.type === 'HEADINGS/HEADING-TWO') {

          styleName = `col-12 ${styles.productsMainHeader}`;
          data = `<h2>${item.text}</h2>`;


        } else {

          styleName = `col-12 ${styles.productsMainSubtitle}`;
          data = `<span>${item.text}</span>`;

        }

        const text = (
          <div
            key={`${this.props.section._id}_elem_${index}`}
            className={styleName}
          >
            { HTMLParser(data) }
          </div>
        );

        top.push(text);

      }

      return null;

    });

    const alignStyle = `align${this.state.align}`;
    let open;
    if (this.state.showModal) open = { zIndex: 999 };

    return (
      <div className={`container ${styles[`productsContainer${this.state.full}`]}`} style={open}>
        <div className={`row ${styles[alignStyle]}`}>
          { top }
          { activeComponents }
        </div>
      </div>
    );

  }

}

ProductsLayout.propTypes = {
  page: PropTypes.string,
  section: PropTypes.shape({}),
  layout: PropTypes.string,
};

export default CSSModules(
  ProductsLayout, styles,
  { allowMultiple: true },
);

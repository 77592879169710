import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './styles.module.css';

class ModalBoxInline extends React.Component {

  constructor(props) {

    super(props);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleListClick = this.handleListClick.bind(this);

  }

  handleCloseClick(event) {

    if (event.type === 'click' || (event.type === 'keypress' && event.key === 'Enter')) {

      if (this.props.onCloseClick) {

        this.props.onCloseClick('ModalBox');

      }

    }

  }

  handleListClick(event) {

    if (event.type === 'click' || (event.type === 'keypress' && event.key === 'Enter')) {

      if (this.props.onListClick) {

        this.props.onListClick(event, this.props.id);

      }

    }

  }

  render() {

    if (this.props.isOpen) {

      const mobile = document.documentElement.clientWidth <= 767;

      let modalPosition;
      const position = {};
      if (this.props.top) {

        modalPosition = styles.modalBoxTop;

      } else if (this.props.position !== undefined) {

        modalPosition = styles.modalBox;
        position.left = this.props.position.x;
        position.top = this.props.position.y;
        if (
          this.props.position.x !== undefined && this.props.position.x.includes('px') &&
          this.props.position.y !== undefined && this.props.position.y.includes('%')
        ) {

          position.transform = `translateY(-${this.props.position.y})`;

        } else if (
          this.props.position.y !== undefined && this.props.position.y.includes('px') &&
          this.props.position.x !== undefined && this.props.position.x.includes('%')
        ) {

          position.transform = `translateX(-${this.props.position.x})`;

        } else if (
          this.props.position.x !== undefined && this.props.position.x.includes('%') &&
          this.props.position.y !== undefined && this.props.position.y.includes('%')
        ) {

          position.transform = `translate(-${this.props.position.x}, -${this.props.position.y})`;

        }


      } else {

        modalPosition = styles.modalBoxCenter;

      }

      const indicatorPosition = {};
      if (this.props.indicator) {

        indicatorPosition.left = this.props.indicatorPosition.x;
        indicatorPosition.top = this.props.indicatorPosition.y;
        if (
          this.props.indicatorPosition.x !== undefined && this.props.indicatorPosition.x.includes('px') &&
          this.props.indicatorPosition.y !== undefined && this.props.indicatorPosition.y.includes('%')
        ) {

          indicatorPosition.transform = `translateY(-${this.props.indicatorPosition.y})`;

        } else if (
          this.props.indicatorPosition.y !== undefined && this.props.indicatorPosition.y.includes('px') &&
          this.props.indicatorPosition.x !== undefined && this.props.indicatorPosition.x.includes('%')
        ) {

          indicatorPosition.transform = `translateX(-${this.props.indicatorPosition.x})`;

        } else if (
          this.props.indicatorPosition.x !== undefined && this.props.indicatorPosition.x.includes('%') &&
          this.props.indicatorPosition.y !== undefined && this.props.indicatorPosition.y.includes('%')
        ) {

          indicatorPosition.transform = `translate(-${this.props.indicatorPosition.x}, -${this.props.indicatorPosition.y})`;

        }

      }

      const backdrop = this.props.backdrop !== undefined ?
        styles[this.props.backdrop] : styles.backdrop;
      const modalAnchorStyle = this.props.noHeaderOnMobile ? {height: '100%'} : {};

      return (
        <div>
          <div className={backdrop} />
          {
            this.props.indicator &&
            (
              <div style={indicatorPosition} className={styles.indicator} />
            )
          }
          <div style={position} className={modalPosition}>
            {
              ((this.props.onCloseClick && mobile === true) && !this.props.noHeaderOnMobile)
              &&
              (
                <div className={styles.modalHeader}>
                  <i
                    role="button"
                    tabIndex="0"
                    className="oi-icon oi-26-close"
                    onClick={this.handleCloseClick}
                    onKeyPress={this.handleCloseClick}
                  />
                </div>
              )
            }
            <div
              id="ModalBoxAnchor"
              role="button"
              tabIndex="0"
              style={modalAnchorStyle}
              className={`${this.props.noBox !== true ? styles.modalContent : styles.modalContentNoBox}`}
              onClick={this.handleListClick}
              onKeyPress={this.handleListClick}
            >
              {this.props.data}
            </div>
          </div>
        </div>
      );

    }

  }

}

ModalBoxInline.propTypes = {
  noHeaderOnMobile: PropTypes.bool,
  isOpen: PropTypes.bool,
  noBox: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onListClick: PropTypes.func,
  data: PropTypes.shape({}),
  id: PropTypes.string,
  top: PropTypes.bool,
  indicator: PropTypes.bool,
  backdrop: PropTypes.string,
  position: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
  }),
  indicatorPosition: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
  }),
};

export default CSSModules(ModalBoxInline, styles);
